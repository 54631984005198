<template>
  <div class="vupdetailnew animated fadeInLeft">
    <div class="vupdetailwraper">
      <div
        class="vupbg"
        :style="{ '--bgurl': 'url(' + vupdetail.vupCover + ')' }"
      >
        <div class="drap">
          <a-divider
            ><h2>{{ vupdetail.vupName }}</h2></a-divider
          >
          <div class="flexmode userwrapper">
            <div class="left">
              <a-avatar :size="80" id="picaca" :src="vupdetail.vupFace">
                <template #icon><UserOutlined /></template>
              </a-avatar>
              <div class="vupinfo">
                <div class="mt10">
                  <span class="mr5 space">UID:</span
                  ><a-tag color="#87d068">{{ vupdetail.midId }}</a-tag>
                </div>
                <div class="mt10">
                  <span class="mr5 space">住址:</span
                  ><a-tag color="#87d068">主播选择不公示</a-tag>
                </div>
                <div class="mt10">
                  <span class="mr5 space">粉丝群:</span
                  ><a-tag color="#87d068">主播选择不公示</a-tag>
                </div>
                <div class="mt10">
                  <span class="mr5 space">体重:</span
                  ><a-tag color="#87d068">主播选择不公示</a-tag>
                </div>
                <div class="mt10">
                  <span class="mr5 space">身高:</span
                  ><a-tag color="#87d068">主播选择不公示</a-tag>
                </div>
              </div>
            </div>
            <div class="mid">
              <div class="chatset">阿巴阿巴</div>
            </div>
            <div class="right">
              <div class="mt10">
                <a-button type="primary" ghost @click="showinfo">
                  <template #icon><upload-outlined /></template>
                  人设信息</a-button
                >
              </div>
              <div class="mt10">
                <a-button type="primary" ghost @click="showfig">
                  <template #icon><upload-outlined /></template>
                  形象上传</a-button
                >
              </div>
              <div class="mt10">
                <a-button type="primary" ghost @click="showModal"
                  ><template #icon><upload-outlined /></template
                  >二创上传</a-button
                >
              </div>
              <div class="mt10">
                <a-button type="primary" danger ghost>
                  <template #icon> <link-outlined /></template>
                  B站主页</a-button
                >
              </div>

              <div class="mt10">
                <a-button type="primary" danger ghost
                  ><template #icon> <link-outlined /></template>直 播
                  间</a-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="detailmid mt5">
      <a-tabs
        class="ml10"
        v-model:activeKey="activeKey"
        centered
        :tabBarStyle="{
          background: '#FF6F91',
          color: 'white',
        }"
      >
        <a-tab-pane key="1" tab="形象展示">
          <FigurepicVue />
        </a-tab-pane>
        <a-tab-pane key="2" tab="二创图集">
          <GraphsVue />
        </a-tab-pane>
        <a-tab-pane key="3" tab="表情包" force-render> <MemeVue /> </a-tab-pane>
      </a-tabs>
    </div>
    <a-modal
      v-model:visible="visible"
      title="二创上传"
      cancelText="取消"
      okText="确定"
      :footer="null"
    >
      <PicuploadVue @closevisible="closevisible" v-if="visible" />
    </a-modal>
    <a-modal
      v-model:visible="visibleinfo"
      title="人设信息"
      cancelText="取消"
      okText="确定"
      :footer="null"
    >
      <InforVue @closevisibleinfo="closevisibleinfo" v-if="visibleinfo" />
    </a-modal>

    <a-modal
      v-model:visible="visiblefig"
      title="上传人设"
      cancelText="取消"
      okText="确定"
      :footer="null"
    >
      <Figureupload @closefig="closefig" v-if="visiblefig" />
    </a-modal>
  </div>
</template>
<script>
import {
  UserOutlined,
  LinkOutlined,
  UploadOutlined,
} from "@ant-design/icons-vue";
import { ref, onMounted, reactive, toRefs } from "vue";
import PicuploadVue from "./components/picupload.vue";
import InforVue from "./components/infor.vue";
import Figureupload from "./components/figureupload.vue";

import FigurepicVue from "./components/figurepic.vue";
import GraphsVue from "./components/graphs.vue";
import MemeVue from "./components/meme.vue";
export default {
  components: {
    UserOutlined,
    LinkOutlined,
    UploadOutlined,
    PicuploadVue,
    InforVue,
    FigurepicVue,
    GraphsVue,
    MemeVue,
    Figureupload,
  },
  setup() {
    const visible = ref(false);
    const visibleinfo = ref(false);
    const visiblefig = ref(false);
    const activeKey = ref("1");
    const status = reactive({
      vupdetail: {},
    });
    onMounted(() => {
      status.vupdetail = JSON.parse(localStorage.getItem("vupdetail"));
    });
    function showModal() {
      visible.value = true;
    }
    function closevisible() {
      visible.value = false;
    }
    function showinfo() {
      visibleinfo.value = true;
    }

    function closevisibleinfo() {
      visibleinfo.value = false;
    }
    function showfig() {
      visiblefig.value = true;
    }
    function closefig() {
      visiblefig.value = false;
    }
    return {
      ...toRefs(status),
      activeKey,
      visible,
      visibleinfo,
      visiblefig,
      closevisible,
      showModal,
      showinfo,
      closevisibleinfo,
      showfig,
      closefig,
    };
  },
};
</script>
<style lang="scss" scoped>
#picaca:hover {
  transform: rotate(666turn);
  transition-property: all;
  transition-duration: 59s;
  transition-timing-function: cubic-bezier(0.34, 0, 0.81, 1);
  transition-delay: 1s;
}
.vupdetailnew {
  width: 100%;
  height: 100%;
  .vupdetailwraper {
    width: 100%;
    height: 550px;

    .vupbg {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: center center no-repeat;
        background-image: var(--bgurl);
        filter: blur(4px);
        z-index: 0;
        background-size: cover;
      }
    }
    .vupbg .drap {
      position: absolute;
      z-index: 1;
      width: 70%;
      height: 400px;
      box-shadow: 0 4px 6px 2px #b4b4b4;
      border-radius: 10px;
      left: 50%;
      top: 50%;
      margin-left: -35%;
      margin-top: -200px;
      // transform: translate(-50% -50%);
      background: white;
      opacity: 0.9;
      padding: 10px;
      .userwrapper {
        width: 100%;
        // height: 100%;
      }
      .left {
        width: 200px;
        height: 100%;
        border-right: solid 1px #ccc;
        .vupinfo {
          div {
            .space {
              font-size: 15px;
              display: inline-block;
              width: 60px;
              text-align: left;
            }
          }
        }
      }
      .mid {
        flex: 1;
        .chatset {
          font-size: 22px;
          text-indent: 2em;
        }
      }
      .right {
        width: 200px;
        height: 250px;
        border-left: solid 1px #ccc;
      }
    }
  }
}
</style>
