<template>
  <div class="graphs">二创区</div>
</template>
<script>
export default {
  setup() {},
};
</script>
<style lang="scss" scoped>
.graphs {
}
</style>
