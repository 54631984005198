<template>
  <div class="infor">
    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="住址">
        <a-input v-model:value="formState.address" />
      </a-form-item>
      <a-form-item label="粉丝群">
        <a-input v-model:value="formState.group" />
      </a-form-item>
      <a-form-item label="体重">
        <a-input v-model:value="formState.weight" />
      </a-form-item>
      <a-form-item label="身高">
        <a-input v-model:value="formState.height" />
      </a-form-item>
      <a-form-item label="人设介绍">
        <a-textarea v-model:value="formState.chart" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 14, offset: 8 }">
        <a-button type="primary" @click="confirm">确定</a-button>
        <a-button style="margin-left: 10px" @click="cancel">取消</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { reactive } from "vue";
export default {
  setup(props, context) {
    const formState = reactive({
      address: "",
      group: "",
      weight: "",
      height: "",
      chart: "",
    });

    function confirm() {
      console.log("submit!", formState);
      context.emit("closevisibleinfo");
    }
    function cancel() {
      context.emit("closevisibleinfo");
    }

    return {
      labelCol: {
        style: {
          width: "150px",
        },
      },
      wrapperCol: {
        span: 14,
      },
      formState,
      confirm,
      cancel,
    };
  },
};
</script>
<style lang="sass" scoped></style>
