<template>
  <div class="meme">表情包区</div>
</template>
<script>
export default {
  setup() {},
};
</script>
<style lang="scss" scoped>
.meme {
}
</style>
